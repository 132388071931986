import React, {useCallback, useMemo} from 'react';
import classNames from "classnames";
import {Else, If, Then} from "react-if";
import {BaseEdge, EdgeLabelRenderer, getBezierPath, useReactFlow,} from '@xyflow/react';

import './Edge.css';

export default function CustomEdge({
                                       id,
                                       sourceX,
                                       sourceY,
                                       targetX,
                                       targetY,
                                       sourcePosition,
                                       targetPosition,
                                       style = {},
                                       markerEnd,
                                       source: sourceId,
                                       target: targetId,
                                       label,
                                       data,
                                       ...props
                                   }) {
    const {setEdges, getNodes, getEdges, updateEdge} = useReactFlow();

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });
    const sourceNode = useMemo(() => getNodes().find((node) => node.id === sourceId), [getNodes, sourceId]);
    const responses = useMemo(() => {
        try {
            const type = sourceNode.data.type;
            return type.responses || [];
        } catch (e) {
        }
        return [];
    }, [sourceNode]);
    const onSelectHandler = useCallback((e) => {
        //selected response
        const response = e.target.value;

        const allEdges = getEdges();

        const nodeEdges = allEdges.filter(({source}) => source === sourceId);

        const currentLabel = label;
        // set the current edge to the selected response
        const currentNode = nodeEdges.find(({id: _id}) => id === _id);
        if (currentNode) {
            currentNode.label = response;
            // if you find any edge that is already the same, set it to the label of the edge that was selected
            const nodeWithTheSameLabel = nodeEdges.find(({id: _id, label}) => label === response && id !== _id);
            if (nodeWithTheSameLabel) {
                nodeWithTheSameLabel.label = currentLabel;
            }
        } else {
            console.log('Current node not found');
        }

        setEdges(allEdges);
    }, [getEdges, id, label, setEdges, sourceId]);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    <If condition={sourceId === '0' || responses.length === 0}>
                        <Then></Then>
                        <Else>
                            {() =>
                                <select className={classNames({
                                    "responseSelector": true,
                                    "disabled": responses.length <= 1
                                })} onChange={onSelectHandler}
                                        value={label}
                                >
                                    {responses.map((r) => <option key={r} value={r}>{r}</option>)}
                                </select>}
                        </Else>
                    </If>

                </div>
            </EdgeLabelRenderer>
        </>
    );
}
