import React, {useCallback, useMemo} from "react";
import {Else, If, Then} from "react-if";
import classNames from "classnames";
import {Handle, Position, useReactFlow} from "@xyflow/react";
import {firstNodeTypes, PossibleNodeTypes} from "../../types";
import "./Node.css";

// Find all the sub-nodes of the target node, recursively
const findSubNodesRecursively = (node, allNodes, allEdges, seen = new Set()) => {
    const nodeId = node.id;
    allEdges.forEach(edge => {
        if (edge.source === nodeId && !seen.has(edge.target)) {
            const targetNode = allNodes.find(({id}) => id === edge.target);
            if (targetNode) {
                seen.add(targetNode);
                findSubNodesRecursively(targetNode, allNodes, allEdges, seen);
            }
        }
    });
    return seen;
};
// find all the edges of the requested nodes
const findNodesEdges = (nodes, allEdges) => {
    return allEdges.filter(edge => nodes.some(node => node.id === edge.source || node.id === edge.target));
};

export const getParentId = (node, edges) => {
    // find the edge that has the node as target
    const edge = findNodesEdges([node], edges)
        .find(({target}) => target === node.id);
    if (edge) {
        // return the parent node
        return edge.source;
    }
    return undefined;
};

export const Node = (node) => {

    const {setEdges, setNodes, getNodes, getEdges} = useReactFlow();
    const [isCreatingNodeAnimation, setCreatingNodeAnimation] = React.useState(false);
    const nodeTypeCode = useMemo(() => node.data?.type?.code, [node.data]);
    const [typeCode, setTypeCodeCode] = React.useState(nodeTypeCode);
    const oldId = useMemo(() => {
        return getParentId(node, getEdges());
    }, [node.data]);
    const noType = !nodeTypeCode;
    const noId = !oldId;

    // delete the node, and all its sub nodes recursively
    const deleteNode = useCallback(() => {
        // if its the first node, instead of deleting it, we want to set its type to undefined
        if (node.id === '0') {
            setNodes((nds) => nds.map((n) => {
                if (n.id === node.id) {
                    return {
                        ...n,
                        data: {}
                    };
                }
                return n;
            }));
            return;
        }

        const allEdges = getEdges();
        const nodeDeleteCandidates = [node, ...findSubNodesRecursively(node, getNodes(), allEdges)];
        const edgeDeleteCandidates = findNodesEdges(nodeDeleteCandidates, allEdges);
        setNodes((nds) => nds.filter((n) => !nodeDeleteCandidates.map(({id}) => id).includes(n.id)));
        setEdges((eds) => eds.filter((e) => !edgeDeleteCandidates.map(({id}) => id).includes(e.id)));
    }, [node.id, getNodes, getEdges, setNodes, setEdges]);
    // visually mark the node and its sub nodes for deletion
    const onDeleteNodeHoverStart = useCallback(() => {
        const allNodes = getNodes();
        const allEdges = getEdges();
        // if its the first node, only mark it for deletion, since it just allows you to change the type
        const nodeDeleteCandidates = node.id === '0' ? [node] : [node, ...findSubNodesRecursively(node, allNodes, allEdges)];
        allNodes
            .filter(({id}) => nodeDeleteCandidates.map(({id}) => id).includes(id))
            .forEach((n) => {
                n.data.deleteMarked = true;
            });
        setNodes([...allNodes]);
    }, [node.id]);
    // unmark all the nodes
    const onDeleteNodeHoverEnd = useCallback(() => {
        // remove all delete marks
        const allNodes = getNodes();
        allNodes.forEach((n) => {
            n.data.deleteMarked = false;
        });
        setNodes([...allNodes]);
    }, [node.id]);
    const possibleTypes = useMemo(() => {
        //if its the literal first node, return the first node types
        if (node.id === '0') {
            return firstNodeTypes;
        }
        return PossibleNodeTypes;

    }, [node.id]);
    const onSelectType = useCallback(() => {
        if (!typeCode) {
            return;
        }
        const type = possibleTypes.find(({code}) => code === typeCode);
        setNodes((nds) => nds.map((n) => {
            if (n.id === node.id) {
                return {
                    ...n,
                    data: {
                        ...n.data, type: type, label: type.label
                    }
                };
            }
            return n;
        }));
    }, [node.id, typeCode]);
    const handleLoadingAnimation = useCallback(() => {
        setCreatingNodeAnimation(true);
        setTimeout(() => {
            setCreatingNodeAnimation(false);
        }, 1500);
    }, []);
    return (
        <div className={classNames({
            "node-container": true,
            "delete-marked": node.data.deleteMarked,
            "no-type": noType && !noId
        })}>
            {!noId ? <Handle type="target" position={Position.Left} id={oldId}/> : null}
            <If condition={noType}>
                <Then>
                    {() =>
                        <div className="label-container">
                            <div>
                                <div>
                                    <select className="types"
                                            onChange={(e, value) => setTypeCodeCode(e.target.value)}
                                            value={typeCode}
                                    >
                                        <option defaultValue={true} value="">Choose Type</option>
                                        {possibleTypes.map((type) => <option key={type.code}
                                                                             value={type.code}>{type.label}</option>)}
                                    </select>
                                </div>
                                <div className="types-save-cancel-buttons">
                            <span className={classNames({
                                "save": true,
                                "disabled": !typeCode
                            })}
                                  onClick={onSelectType}
                            >💾</span>
                                    <span className="cancel" onClick={deleteNode}>🚫</span>
                                </div>
                            </div>
                        </div>
                    }
                </Then>
                <Else>
                    {() =>
                        <>
                            <div className="label-container">{node.data.type.label}</div>
                            <div onMouseEnter={onDeleteNodeHoverStart}
                                 onMouseLeave={onDeleteNodeHoverEnd}
                                 className="trash"
                                 onClick={deleteNode}>❌
                            </div>
                        </>
                    }
                </Else>
            </If>
            <Handle
                type="source"
                position={Position.Right}
                className={
                    classNames({
                        "handle": true,
                        "right": true,
                        "loading-animation": isCreatingNodeAnimation,
                    })
                }
                onClick={handleLoadingAnimation}
            />
        </div>
    );
};