import React, {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import Flows from "./flows/Flows";

const root = createRoot(document.getElementById("root"));
root.render(
    <StrictMode>
        <Flows/>
    </StrictMode>
);