import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow} from '@xyflow/react';
import {getParentId, Node} from "./components/node/Node";
import Edge from "./components/edge/Edge";
import '@xyflow/react/dist/style.css';
import './Flows.css';
import {throttle} from "../utils";
import {pick} from "lodash";


const initialNode = {
    id: '0',
    type: 'custom',
    data: {label: 'Start'},
    position: {x: document.querySelector("#root").clientWidth / 2, y: document.querySelector("#root").clientHeight / 2},
};
let nodeId = 1;
let edgeId = 1;
const getNodeId = (excludedIds) => {
    let id = `${nodeId++}`;
    if (excludedIds && excludedIds.includes(id)) {
        return getNodeId(excludedIds);
    }
    return id;
};
const getEdgeId = (excludedIds) => {
    let id = `${edgeId++}`;
    if (excludedIds && excludedIds.includes(id)) {
        return getEdgeId(excludedIds);
    }
    return id;
};
const nodeTypes = {
    custom: Node, // Register the custom node type
};

const edgeTypes = {
    buttonedge: Edge,
};

const saveToUrl = (rfInstance) => {
    // return;
    return new Promise((resolve) => {
        setTimeout(() => {
            if (!rfInstance) {
                return;
            }
            const flow = rfInstance.toObject();
            // console.log('Saved to URL', pick(flow.nodes[0],Object.keys(initialNode)) , initialNode);
            // if flow is equal to initialNode ,  dont save it, clean the url instead
            if (rfInstance.toObject().nodes.length === 1 &&
                JSON.stringify(pick(flow.nodes[0], Object.keys(initialNode))) === JSON.stringify(initialNode)) {
                window.location.hash = '';
                resolve(window.location.hash);
                return;
            }
            const encoded = encodeURI(JSON.stringify(flow));
            window.location.hash = encoded;
            resolve(window.location.hash);
        }, 100);
    });
};

const Flows = () => {
    const [rfInstance, setRfInstance] = useState(null);
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    const [nodes, setNodes, _onNodesChange] = useNodesState([]);
    const [edges, setEdges, _onEdgesChange] = useEdgesState([]);
    const {setViewport} = useReactFlow();

    const {screenToFlowPosition} = useReactFlow();

    const onConnectStart = useCallback(throttle((event, {
        nodeId,
        handleType,
        ...rest
    }) => {
        if (handleType === 'target') {
            return;
        }
        // pull the current nodes and edges
        const currentNodes = nodes || [];
        const currentEds = edges || [];
        // find the source node that is being connected from
        const node = currentNodes.find((n) => n.id === nodeId);
        let relevantResponse = undefined;
        // does said node have a type?
        if (node.data.type && Array.isArray(node.data.type.responses) && node.data.type.responses.length > 0) {
            // find whether said node still have any available responses
            const currentRelevantEds = currentEds.filter((e) => e.source === nodeId);
            const currentPossibleResponses = node.data.type.responses.filter((r) => !currentRelevantEds.find((e) => e.label === r));
            // if there are still responses available, connect to the first one
            if (currentPossibleResponses.length > 0) {
                relevantResponse = currentPossibleResponses[0];
            } else {
                // we should not even create a node in this case, because no responses left
                return;
            }
        }


        connectingNodeId.current = nodeId;
        const yNodeSpacing = 70;

        // we need to remove the wrapper bounds, in order to get the correct position
        const newNodeId = getNodeId(nodes.map((n) => n.id));
        const newNode = {
            id: newNodeId, type: 'custom',
            position: {
                x: node.position.x + 150 + 30,
                y: node.position.y + (nodes
                    .filter(n => n.data)
                    .filter((n) => getParentId(n, edges) === nodeId).length * yNodeSpacing) - yNodeSpacing,
            },
            data: {
                label: `Node ${newNodeId}`,
            },
            origin: [0.5, 0.0],
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => {
            // if there is a type with responses array, we should make sure to not go beyond the allowed responses
            if (node.data.type && Array.isArray(node.data.type.responses) && relevantResponse) {
                // if there are no edges yet to this node, set the first response
                return eds.concat({
                    id: getEdgeId(eds.map((e) => e.id)),
                    source: connectingNodeId.current,
                    target: newNodeId,
                    label: relevantResponse,
                    type: 'buttonedge'
                });
            }
            // otherwise, just create the new node with its connections
            else {
                return eds.concat({
                    id: getEdgeId(eds.map((e) => e.id)),
                    source: connectingNodeId.current,
                    target: newNodeId,
                    type: 'buttonedge'
                });
            }
        });
        nodes.push(newNode);
        saveToUrl(rfInstance);
    }), [nodes, edges, rfInstance]);

    const onNodesChange = useCallback((nodes) => {
        const r = _onNodesChange(nodes);
        saveToUrl(rfInstance);
        return r;
    }, [_onNodesChange, rfInstance]);
    const onEdgesChange = useCallback((edges) => {
        const r = _onEdgesChange(edges);
        saveToUrl(rfInstance);
        return r;
    }, [_onEdgesChange, rfInstance]);
    // initialize and restore flows from url hash
    useEffect(() => {
        if (screenToFlowPosition) {
            // initialize
            setNodes([{
                ...initialNode,
                position: screenToFlowPosition({
                    ...initialNode.position
                })
            }]);

            // restore from url
            try {
                const h = window.location.hash.slice(1);
                if (!h) {
                    // url is probably empty
                    return;
                }
                const flow = JSON.parse(decodeURI(h));

                if (flow) {
                    const {x = 0, y = 0, zoom = 1} = flow.viewport;
                    setNodes((flow.nodes || []));
                    setEdges(flow.edges || []);
                    setViewport({x, y, zoom});
                    nodeId = flow.nodes.length > 0 ? parseInt([...flow.nodes].pop().id) : 1;
                    edgeId = flow.edges.length > 0 ? parseInt([...flow.edges].pop().id) : 1;
                }
            } catch (e) {
                console.log(e);
            }

        }
    }, [screenToFlowPosition, setNodes, rfInstance]);


    return (<div className="wrapper" ref={reactFlowWrapper}>
        <ReactFlow
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnectStart={onConnectStart}
            fitView
            fitViewOptions={{padding: 2}}
            nodeOrigin={[0.5, 0]}
            onInit={setRfInstance}
        />
    </div>);
};

export default () => (<ReactFlowProvider>
    <Flows/>
</ReactFlowProvider>);
