export const RESPONSE_OK = 'OK';
export const RESPONSE_ERROR = 'ERROR';
export const RESPONSE_WARNING = 'WARNING';
export const RESPONSE_INFO = 'INFO';
export const RESPONSE_UNDECIDED = 'UNDECIDED';

export const NodeTypeTemplate = {
    label: 'Type',
    code: 'type',
    responses: [RESPONSE_OK, RESPONSE_ERROR, RESPONSE_WARNING, RESPONSE_INFO, RESPONSE_UNDECIDED],
};

export const createNodeType = (label, code, responses) => {
    return {...NodeTypeTemplate, label, code, responses};
}

export const firstNodeTypes = [
    createNodeType("Head", "head"),
    createNodeType("Body", "body"),
    createNodeType("Legs", "Legs"),
]

export const PossibleNodeTypes = [
    createNodeType("Test a", "testa", [RESPONSE_OK, RESPONSE_ERROR]),
    createNodeType("Test b", "testb", [RESPONSE_OK]),
    createNodeType("Test c", "testc", [RESPONSE_UNDECIDED, RESPONSE_OK]),
    createNodeType("Split", "splitter"),
];
